<template>
    <div class="login-main">
        <nav-header :search="false" :user="false"></nav-header>
        <div id="particle"></div>
        <el-form @keyup.native.enter="submit" @submit.native.prevent="submit" :rules="rules" class="login-form"
                 ref="form" :model="form" label-width="80px">
            <div class="login-title">
                欢迎登录
            </div>
            <div class="login-type">
                <div @click="changeLoginType(item.type)" v-for="item in  login.type" :key="item.name"
                     :class="{active:login.active===item.type}">
                    <span>
                        {{ item.title }}
                    </span>
                </div>
            </div>
            <div v-show="login.active==='user'">
                <div class="input-item">
                    <div class="label">
                        用户名/手机号
                    </div>
                    <input placeholder="用户名/手机号" type="text" v-model="form.password">
                </div>
                <div class="input-item">
                    <div class="label">
                        密码
                    </div>
                    <input placeholder="请输入密码" type="password" v-model="form.password">
                </div>
                <div class="checkbox-login">
                    <el-checkbox v-model="login.remember">记住我的登录状态</el-checkbox>
                </div>
                <div>
                    <el-button class="login-btn" @click="submit" type="primary">
                        <span class="w">登</span> <span class="w">录</span>
                    </el-button>
                </div>
                <div class="copyright">
                    <el-checkbox v-model="login.agree">
                        我已阅读并同意<a href="">《用户协议》</a>、
                        <a href="">《隐私政策》</a>
                    </el-checkbox>
                </div>
            </div>
            <div v-show="login.active==='wx'">
                <div class="wx-img">
                    <div class="angle"></div>
                    <div class="angle bottom"></div>
                    <div class="scan">

                    </div>
                    <div class="scan-img">
                        <img :src="login.codeUrl" alt="">
                    </div>
                </div>
                <div class="wx-tip">
                    打开微信扫一扫，快速登录/注册
                </div>
            </div>
        </el-form>
        <div class="login-footer">
            Copyright © 成都比格图数据处理有限公司 版权所有 | 蜀ICP备14013992-2号
        </div>
    </div>
</template>
<script>
import storage from "@/utils/storage";
import Cookie from 'js-cookie';
import tools from "@/utils/tools";
import 'particles.js';
import NavHeader from "@/components/common/nav-header.vue";
export default {
    name: "index",
    components: {
        NavHeader,
    },
    data: function () {
        return {
            checkUrl: '/app/office/qrcode',
            login: {
                codeUrl: '',
                uuid: '',
                currentTime: 0,
                scene: '',
                remember: false,
                agree: true,
                active: 'wx',
                type: [
                    {title: '用户名密码登录', type: 'user'},
                    {title: '微信扫一扫登录', type: 'wx'}
                ]
            },
            isLoading: false,
            checked: false,
            rules: {
                username: [
                    {require: true, message: '用户名不可以为空!', trigger: 'blur'}
                ],
                password: [
                    {require: true, message: '密码不可以为空!', trigger: 'blur'}
                ]
            },
            form: {
                username: '',
                password: ''
            }
        }
    },
    mounted() {
        if (this.login.active === 'wx') {
            this.getQrcodeUrl();
        }
        window.particlesJS.load('particle', '/static/manage/data/particle.json');
    },
    methods: {
        getQrcodeUrl() {
            this.login.scene = 'bb' + Math.random();
            this.login.uuid = 'uu' + Math.random();
            this.login.currentTime = new Date().getTime();
            this.login.codeUrl = 'http://api.bigemap.com/app/office/qrcode?uuid=uuid&scene=' + this.login.scene;
            this.checkLogin();
        },
        checkLogin() {
            if (new Date().getTime() - this.login.currentTime > 1000 * 60 * 3) {

                return;
            }
            clearTimeout(this.login.checkTimer);
            this.login.checkTimer = setTimeout(() => {
                this.$bbs.post(this.checkUrl, {
                    scene: this.login.scene,
                    uuid: this.login.uuid,
                }, {elseConfig: {showError: false}}).then((data) => {
                    // this.checkLogin();
                    console.log(data);
                    if (data.code !== 200) {
                        this.$message.warning(data.msg || data.message || '登陆失败');
                        return;
                    }
                    this.$store.dispatch('userData', data.data.userData);
                    storage.token(data.data.token);
                    //获取有可能存在的缓存路径
                    let jumpUrl = storage.storage('cache_url');
                    if (!jumpUrl) jumpUrl = '/wenda/index';
                    this.$router.push({
                        path: jumpUrl,
                    });
                    this.$message.success('登陆成功!');
                }).catch(() => {
                    this.checkLogin();
                });
            }, 2000);
        },
        userLogin: function () {
            // this.$axios.post('/manage/login/login',this.form).then(data=>{
            this.$app.post('/bigmap/login/login', this.form).then(data => {
                if (!data || data.code !== 200) return;
                let menu = data.data.menu.map(v => {
                    v.url = v.url.replace('.html', '');
                    return v;
                });
                Cookie.set(process.env.VUE_APP_COOKIE, new Date().getTime());
                storage.token(data.data.token);
                storage.permission(menu);
                storage.storage('menu_type', data.data.type);
                this.$store.dispatch('permission', menu);
                let jumpUrl = tools.getJumpUrl('/app/index');
                if (!jumpUrl) {
                    this.$message.warning('您还没有任何权限!');
                    storage.clear();
                    return;
                }
                this.$message.success('登陆成功!');
                this.$store.dispatch('userData', data.data.userData);
                this.$router.push({path: jumpUrl});
            });
        },
        changeLoginType(type) {
            this.login.active = type;
        },
        submit() {
            if (this.isLoading || this.$store.state.loading) return;
            this.isLoading = true;
            setTimeout(() => {
                this.isLoading = false;
            }, 500);
            this.$refs.form.validate((status) => {
                if (!status) return;
                this.userLogin();
            });
        },
    },
    beforeDestroy() {
        clearTimeout(this.login.checkTimer);
    }
}
</script>
<style lang="less">

.login-main {
    background: url('/static/bbs/images/login/login_bg.png') top center no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    overflow: hidden;
    top: 0;
    user-select: none;
    bottom: 0;
    right: 0;

    .login-form {
        //background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
        //background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%);
        //background-image: linear-gradient(120deg, rgba(137, 247, 254, 0.6) 0%, rgba(102, 166, 255, 0.7) 100%);
        /*background-image: linear-gradient(to top, #fbc2eb 0%, #a6c1ee 100%);*/
        background-color: #fff;
        padding: 70px 25px;
        position: absolute;
        right: 10%;
        border-radius: 10px;
        top: 150px;
        width: 450px;

        .logo-img {
            img {
                height: 90px;
            }

            text-align: center;
        }

        .login-title {
            font-size: 32px;
            /*letter-spacing: 1rem;*/
            text-align: center;
            color: #333;
            padding: 0 0 20px 0;
        }

        .input-item {
            padding: 10px 0;
            position: relative;

            &::after {
                content: ' ';
                display: block;
                position: absolute;
                width: 100%;
                height: 1px;
                background-color: #e3e2e2;
            }

            .label {
                padding: 10px 0;
            }

            input {
                font-size: 16px;
                border: none;
                display: inline-block;
                width: 100%;
                padding: 5px 0;
                letter-spacing: 1px;
                border-radius: 4px;

                &:-webkit-autofill {
                    box-shadow: 0 0 0px 1000px white inset;
                    -webkit-box-shadow: 0 0 0px 1000px white inset;
                    -webkit-text-fill-color: #333;
                }

                &[type=password] {
                    letter-spacing: 2px;

                }

                &::placeholder {
                    font-size: 14px;
                }

                &:focus {
                    outline: none;
                }
            }

            //.my-label {
            //    .el-form-item__label {
            //        font-size: 18px;
            //    }
            //}
        }

        .wx-tip {
            font-size: 14px;
            color: #777777;
            padding: 5px;
            text-align: center;
        }

        .wx-img {
            padding: 15px;
            overflow: hidden;
            width: 260px;
            margin: 10px auto;
            height: 260px;
            border: 1px solid #eee;
            position: relative;

            .angle {
                position: absolute;
                top: 0;
                bottom: 0;

                &.bottom {
                    right: 0;
                    top: 0;
                    bottom: 0;

                    &::before {
                        position: absolute;
                        display: block;
                        content: '';
                        width: 30px;
                        height: 30px;
                        left: -30px;
                        top: 0;
                        border: 2px solid transparent;
                        border-right-color: #cccccc;
                        border-top-color: #cccccc;
                    }

                    &::after {
                        position: absolute;
                        display: block;
                        content: '';
                        width: 30px;
                        height: 30px;
                        left: -30px;
                        bottom: 0;
                        border: 2px solid transparent;
                        border-right-color: #cccccc;
                        border-bottom-color: #cccccc;
                    }
                }

                &::before {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 30px;
                    height: 30px;
                    //right: -15px;
                    left: -15px;
                    top: 0;
                    border: 2px solid transparent;
                    border-left-color: #cccccc;
                    border-top-color: #ccc;
                }

                &::after {
                    position: absolute;
                    display: block;
                    content: '';
                    width: 30px;
                    height: 30px;
                    left: -15px;
                    bottom: 0;
                    border: 2px solid transparent;
                    //border-color: transparent;
                    border-left-color: #ccc;
                    border-bottom-color: #ccc;
                }

            }

            .scan {
                left: 0;
                width: 100%;
                height: 25px;
                z-index: 99;
                border-bottom: 1px solid rgba(204, 143, 52, 0.5);
                position: absolute;
                background-image: linear-gradient(180deg, rgba(0, 255, 51, 0) 10%, rgba(204, 138, 52, 0.2) 100%);
                top: -25px;
                animation: move 3s linear infinite;

            }

            .scan-img {
                text-align: center;
                width: 100%;
                height: 100%;

                img {
                    display: inline-block;
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .copyright, .checkbox-login {
            padding: 10px 0;
            //padding-left: 80px;
        }

        .login-btn {
            //letter-spacing: 50px;
            display: block;
            width: 100%;
            padding: 12px 0;
            font-size: 18px;

            .w {
                padding: 0 25px;
            }
        }
    }
}
.login-type {
    display: flex;
    color: #666;
    align-items: center;
    border-bottom: 1px solid #cccccc;
    justify-content: space-around;

    > div {
        flex: 1;
        padding-bottom: 8px;
        text-align: center;
        position: relative;
        cursor: pointer;

        &.active {
            color: #51a4fe;

            &::after {
                width: 100%;
            }
        }

        &::after {
            content: ' ';
            display: block;
            width: 0;
            height: 2px;
            bottom: -2px;
            position: absolute;
            transition: all ease-in-out .25s;
            background-color: #51a4fe;
        }
    }
}

.login-footer {
    position: fixed;
    left: 0;
    right: 0;
    color: #B3C1D3;
    text-align: center;
    bottom: 20px;
    font-size: 12px;
}


#particle {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

@keyframes move {
    0% {
        top: -25px
    }
    100% {
        top: 270px;
    }
}
</style>
